
import * as React from "react"
import { SuppInside } from "@/components/h5/Support"

const Index = () => {
  return (
    <div>
      <SuppInside />
    </div>
  )
}

export default Index
