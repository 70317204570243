import * as React from "react"
import s from "./index.module.less"
const langList = {
  'de': "en",
  'en': 'en',
  'es': "en",
  'fr': 'en',
  'it': "en",
  'ja': "ja",
  'ko': "ko",
  'zh': "en"
}
export const SuppInside = ({ fdRef }) => {
  const [lang, setLang] = React.useState('en')
  const [langJson, setLangJson] = React.useState()
  let email = !!langJson && langJson["Support.email"]
  React.useEffect(() => {
    let link = window.location.href.split('?lang=')[1]?.slice(0, 2)
    let langListKeyArr = Object.keys(langList)
    if (!!link && langListKeyArr.includes(link)) {
      setLang(langList[link])
      getLanAjax(langList[link])
    } else {
      setLang('en')
      getLanAjax('en')
    }
  }, [])
  const getLanAjax = (lang) => {
    fetch(
      `${process.env.resourceUrl}locales/${lang}.json`
    ).then(response => response.json()).then(res => {
      setLangJson(res)
    })
  }
  return (
    <>
      <div className={s.supportWrapper}>
        <a
          name="contact-us"
          id={`contact-us`}
          href={`#contact-us`}
          style={{ visibility: "hidden" }}
        />
        <div className={s.domainTitle}>{!!langJson && langJson["Support.afterSales"]}</div>
        <div className={s.tableLevel1}>
          <div className={s.tableLevel2}>
            <div className={s.title}>{!!langJson && langJson["Support.SouthKorea"]}</div>
            <div className={s.noLinkRecord}>
              {email}:&ensp;
              <span>korea@xreal.com</span>
            </div>
          </div>
          <div className={s.tableLevel2}>
            <div className={s.title}>{!!langJson && langJson["Support.Japan"]}</div>
            <div className={s.noLinkRecord}>
              {email}:&ensp;
              <span>jpas@xreal.com</span>
            </div>
          </div>

          <div className={s.tableLevel2}>
            <div className={s.title}>{!!langJson && langJson["Support.Germany"]}</div>
            <div className={s.noLinkRecord}>
              {email}:&ensp;
              <span>support@xreal.com</span>
            </div>
          </div>
          <div className={s.tableLevel2}>
            <div className={s.title}>{!!langJson && langJson["Support.Other"]}</div>
            <div className={s.noLinkRecord}>
              {email}:&ensp;
              <span>support@xreal.com</span>
            </div>
          </div>
        </div>
        <div className={s.domainTitle}>{!!langJson && langJson["Support.technicalSupport"]}</div>
        <div className={s.tableLevel1}>
          <div className={s.tableLevel2}>
            <div className={s.title}>{!!langJson && langJson["Support.developerCommunityForum"]}</div>
            <div className={s.noLinkRecord}>
              <a href={process.env.url_community} target="_blank">{process.env.url_community}</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
